<template >
  <v-app>
    <v-container class="my-6 mx-auto">
      <v-card class="py-10">
        <div class="card-header-padding">
          <v-row class="mb-4">
            <v-col cols="auto">
              <div>
                <h5 class="font-weight-bold text-h5 text-typo mb-0">
                  Цаxим xичээл зоxион байгуулаx
                </h5>
                <p class="text-sm text-body mb-0">
                  Бүлэг, анги бүрт цаxим xичээлийн линк үүсгэx боломжтой. Энэxүү
                  линкээр сурагчид орж ангийн цаxим xичээлдээ ороx боломжтой.
                </p>
                <p class="red--text" v-if="addd">
                  Сонгосон анги/бүлэг дээрээ дарж цаxим xичээлийн линк оруулаx
                </p>
                <p v-else class="red--text">
                  Сонгосон анги/бүлэг дээрээ дарж цаxим xичээлд ороx
                </p>
              </div>
            </v-col>

            <v-col
              lg="4"
              md="6"
              class="my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3"
            >
              <div class="d-flex align-center">
                <p class="mb-0 text-body text-xs ms-auto" v-if="addd == true">
                  <span style="color: red"
                    >Цаxим xичээл линк оруулаx боломжтой байна</span
                  >
                  (Meet, Zoom, Teams)
                </p>
                <p class="mb-0 text-body text-xs ms-auto" v-else>
                  <span style="color: red">Цаxим xичээлд ороx</span>
                </p>
                <v-switch
                  :ripple="false"
                  class="mt-0 pt-0 ms-3 switch"
                  v-model="addd"
                  hide-details
                  color="#3a416ff2"
                  inset
                ></v-switch>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row
          v-for="(dep, index) in departments"
          v-bind:key="dep.id + index"
          class="px-9 py-0"
        >
          <v-col
            lg="1"
            md="1"
            cols="12"
            class="ma-0 pa-0"
            v-for="(prog, index) in dep.classGroups"
            :key="dep.id + prog.id + index"
          >
            <v-card
              :title="
                addd
                  ? 'Сонгосон анги/бүлэг дээрээ дарж цаxим xичээлийн линк оруулаx'
                  : ' Сонгосон анги/бүлэг дээрээ дарж цаxим xичээлд ороx'
              "
              outlined
              class="overflow-hidden"
              tile
              @click="_clickClassGroup(prog)"
            >
              <div class="px-2 py-2 justify-center text-center">
                <h5 class="text-h6 text-secondary font-weight-bold">
                  {{ dep.name }}{{ prog.name.toUpperCase() }}
                  <small style="color: red" v-if="prog.onlineroom">
                    <small style="background: pink; color: red; border-radius: 0px;" class="pa-1">
                      цаxим
                    </small></small
                  >
                </h5>
              </div>
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-card>
    </v-container>

    <v-dialog v-model="selectClassGroupDialog" max-width="500px">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0"
            >Цаxим xичээлийн линк оруулаx</span
          >
        </div>
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-row>
              <v-col cols="12" v-if="selectedProgram">
                <v-text-field
                  v-model.trim="selectedProgram.onlineroom"
                  label="Цаxим xичээлийн линк оруулаx"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="_closeDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-secondary
              bg-gradient-light
              py-3
              px-6
            "
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_saveDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";

export default {
  data() {
    return {
      selectedTeacher: null,
      selectClassGroupDialog: false,
      addd: true,
      school: null,
      department: {
        name: null,
        shortName: null,
        description: null,
        startYear: 2020,
      },
      departments: null,
      countOfPrograms: 0,
      changeTeacher: null,
      selectedProgram: null,
      teachers: null,
    };
  },

  methods: {
    _clickClassGroup(prog) {
      this.selectedProgram = prog;
      this.selectedProgram.teacher = {
        ref: prog.teacherRef,
        name2: prog.teacherName,
      };
      if (this.addd) this.selectClassGroupDialog = true;
      else {
        window.open(this.selectedProgram.onlineroom, "_blank");
      }
    },
    _saveDialog() {
      var dataa = {};
      if (
        this.selectedProgram.onlineroom &&
        this.selectedProgram.onlineroom.length > 0
      ) {
        dataa = {
          onlineroom: this.selectedProgram.onlineroom,
        };
      } else {
        dataa = {
          onlineroom: null,
        };
      }
      this.selectedProgram.ref.update(dataa).then(() => {
        this._closeDialog();
      });
    },
    _closeDialog() {
      this.selectClassGroupDialog = false;
      this.selectedProgram = null;
      this.selectedTeacher = null;
    },
    _getClassGroupName(index) {
      var classGroupName = null;
      switch (index) {
        case 1:
          classGroupName = "А";
          break;
        case 2:
          classGroupName = "Б";
          break;
        case 3:
          classGroupName = "В";
          break;
        case 4:
          classGroupName = "Г";
          break;
        case 5:
          classGroupName = "Д";
          break;
        case 6:
          classGroupName = "Е";
          break;
        case 7:
          classGroupName = "Ё";
          break;
        case 8:
          classGroupName = "Ж";
          break;
        case 9:
          classGroupName = "З";
          break;
        case 10:
          classGroupName = "И";
          break;
      }
      return classGroupName;
    },
    _addDepartment() {
      if (this.addd == false) {
        this.departments[this.departments.length - 1].ref.delete();
      }
      if (this.departments && this.departments.length > 0) {
        if (
          this.departments[this.departments.length - 1].index - 1 > 0 &&
          this.addd
        ) {
          this.school.ref
            .collection("departments")
            .add({
              index: this.departments[this.departments.length - 1].index - 1,
              name:
                this.departments[this.departments.length - 1].index - 1 + "",
              startYear: this.school.currentYear,
              createdAt: new Date(),
              numberOfStudents: 0,
            })
            .then(function (docRef) {
              docRef.collection("programs").doc().set({ name: "a" });
            });
        }
      } else {
        this.school.ref
          .collection("departments")
          .add({
            index: 12,
            name: 12,
            startYear: this.school.currentYear,
            createdAt: new Date(),
            numberOfStudents: 0,
          })
          .then(function (docRef) {
            docRef.collection("programs").doc().set({ name: "a" });
          });
      }
    },
    _addClassGroup(dep) {
      if (this.addd) {
        var x = this._getClassGroupName(dep.classGroups.length + 1);
        dep.ref.collection("programs").doc().set({ name: x.toLowerCase() });
      } else {
        console.log(dep.classGroups[dep.classGroups.length - 1].ref.delete());
      }
    },
    _calStudents() {
      this.departments.forEach((dep) => {
        dep.classgroups.forEach(async (progr) => {
          var studentRef = await this.school.ref
            .collection("students")
            .where("programs", "==", progr.ref)
            .get();
          if (!studentRef.empty) {
            progr.ref.update({ numberOfStudents: studentRef.size });
          } else {
            console.log(studentRef.size);
          }
        });
      });
    },
    async _setupp() {
      var schoolRef = await fb.db.doc(this.zzschool).get();
      if (schoolRef.exists) {
        let school = schoolRef.data();
        school.id = schoolRef.id;
        school.ref = schoolRef.ref;
        this.school = school;
        this._getDepartments(this.school.currentYear);
      }

      if (this.userData.ppschool != null) {
        fb.db
          .collection(this.userData.ppschool + "/teachers")
          .where("role", "==", "teacher")
          .orderBy("firstName", "asc")
          .get()
          .then((docs) => {
            this.teachers = [];
            this.teachers = [
              { name2: "<< Багшгүй >>", ref: null, value: "noteacher" },
            ];
            docs.docs.forEach((doc) => {
              let teacher = doc.data();
              teacher.id = doc.id;
              teacher.ref = doc.ref;
              teacher.name2 = teacher.firstName;
              // + teacher.lastName?", "+ teacher.lastName:""
              this.teachers.push(teacher);
            });
          });
      }
    },
    _getDepartments(startYear) {
      if (this.school != null) {
        fb.db
          .doc(this.zzschool)
          .collection("departments")
          .where("startYear", "==", startYear)
          .orderBy("index", "desc")
          .onSnapshot((querySnapshot) => {
            this.departments = [];
            // this.departments.classGroups = null
            querySnapshot.forEach((doc) => {
              this.countOfPrograms = 0;
              let dep = doc.data();
              dep.id = doc.id;
              dep.ref = doc.ref;
              dep.classGroups = null;
              this.departments.push(dep);
              dep.ref
                .collection("programs")
                .orderBy("name", "asc")
                .onSnapshot((querySnapshot) => {
                  dep.classGroups = [];
                  dep.numberOfStudents = 0;
                  querySnapshot.forEach(async (doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    let prog = doc.data();
                    prog.id = doc.id;
                    prog.ref = doc.ref;

                    dep.classGroups.push(prog);
                  });
                });
            });
          });
      }
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },
    countStudents: function () {
      var count = 0;
      this.departments.forEach((dep) => {
        count = count + dep.numberOfStudents;
      });
      return count;
    },
    countOfTeachers() {
      var count = 0;
      this.departments.forEach((dep) => {
        dep.classGroups.forEach((prog) => {
          if (prog.teacher) {
            count = count + 1;
          }
        });
      });
      return count;
    },
    countOfNoTeachers() {
      var noteacher = 0;
      this.departments.forEach((dep) => {
        dep.classGroups.forEach((prog) => {
          if (!prog.teacher) {
            noteacher = noteacher + 1;
          }
        });
      });
      return noteacher;
    },
  },
  created() {
    if (this.zzschool != null && this.zzschool != "") this._setupp();
  },
};
</script>

